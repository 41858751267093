import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import AlphaListPage from '../../components/AlphaListPage';

const IndicatorExplanation = () => {
  const { copy, items } = useStaticQuery(graphql`
    query getIndicatorExplanation {
      copy: datoCmsIndicatorsExplanation {
        title
        intro
        seoMetaTags {
          tags
        }
      }
      items: allDatoCmsIndicatorExplanation(
        sort: { order: ASC, fields: indicator }
      ) {
        edges {
          node {
            title: indicator
            body: explanation
          }
        }
      }
    }
  `);

  return <AlphaListPage copy={copy} items={items} />;
};

export default IndicatorExplanation;
